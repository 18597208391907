import { createContext, useContext, useRef } from 'react';

export const LayoutContext = createContext();
export const useLayoutContext = () => useContext(LayoutContext);

export const LayoutContextProvider = ({ children }) => {
    const headerWrapperRef = useRef(null);
    const headerCopyWrapperRef = useRef(null);
    const decorativeLineRef = useRef(null);
    const showcaseFirstHeadingRef = useRef(null);
    const showcaseBackgroundRef = useRef(null);

    return (
        <LayoutContext.Provider
            value={{
                headerWrapperRef,
                headerCopyWrapperRef,
                decorativeLineRef,
                showcaseBackgroundRef,
                showcaseFirstHeadingRef,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};
