import Head from 'next/head';
import React, { useMemo } from 'react';

import { cloudflareLoader } from 'src/components/global/cloudflareImage';

/**
 * Generates <meta> tags for activations and automatically adds them to the
 * page's <head> tag.
 *
 * This component should be placed in the main layout for an activation.
 * Additional <meta> tags can be added as children.
 *
 * @param {Object} params
 * @param {React.Children[]} params.children Additional <meta> tags.
 * @param {string} params.pageTitle The page title.
 * @param {?string} params.shareTitle The title to use for social media
 * previews, i.e. what appears when shared on Twitter or Facebook.
 * @param {?string} params.description A description to show in search engines
 * and on social media previews. HTML will be removed.
 * @param {string} params.canonicalUrl The canonical, permanent URL, that will be provided to search engines.
 * @param {?string} params.imageURl A URL to the image that will be used for
 * social media previews. It will be optimized by Cloudflare if the image is
 * hosted remotely.
 * @param {string} params.siteName The "website name" to use on social media
 * previews.
 * @param {?string} params.ogType The Open Graph entity type. Generally, this
 * is the generic type ("website")
 */
export default function MetaTags({
    children,
    pageTitle = 'WIT | #1 Digital Activation Platform | Capture First-Party Data',
    shareTitle = 'WIT | #1 Digital Activation Platform | Capture First-Party Data',
    description = 'WIT is the best-in-class digital activation platform that helps sports teams and brands elevate their marketing efforts and enhance their brand partnerships with some of the most cutting-edge, turnkey experiences in the world.',
    canonicalUrl = 'https://witcontests.com',
    imageUrl = 'https://wit-web-assets.s3.amazonaws.com/website/wit-meta.png',
    siteName = 'WIT | #1 Digital Activation Platform | Capture First-Party Data',
    ogType = 'website',
}) {
    const optimizedShareImageUrl = useMemo(() => {
        let url = '';
        if (imageUrl) {
            url = cloudflareLoader({ src: imageUrl, width: 854, quality: 80 });
            if (!url.startsWith('http')) {
                url = CONFIG.appUrl + url;
            }
        }

        return url;
    }, [imageUrl]);

    return (
        <Head>
            <title>{pageTitle}</title>
            {!!canonicalUrl && <link rel="canonical" content={canonicalUrl} />}
            <meta
                key="og:title"
                name="title"
                property="og:title"
                content={shareTitle || pageTitle}
            />
            <meta key="og:type" property="og:type" content={ogType} />
            {!!canonicalUrl && (
                <meta key="og:url" property="og:url" content={canonicalUrl} />
            )}
            <meta
                key="og:site_name"
                property="og:site_name"
                content={siteName}
            />
            <meta
                key="twitter:title"
                name="twitter:title"
                content={shareTitle || pageTitle}
            />
            {!!description && (
                <>
                    <meta key="description" name="description" />
                    <meta key="og:description" property="og:description" />
                    <meta
                        key="twitter:description"
                        name="twitter:description"
                    />
                </>
            )}
            {!!imageUrl && (
                <>
                    <meta
                        key="og:image"
                        name="image"
                        property="og:image"
                        content={optimizedShareImageUrl}
                    />
                    <meta
                        key="twitter:image"
                        property="twitter:image"
                        content={optimizedShareImageUrl}
                    />
                    <meta
                        key="twitter:card"
                        name="twitter:card"
                        content="summary_large_image"
                    />
                </>
            )}
            {children}
        </Head>
    );
}
