import { Nav } from 'react-bootstrap';

import LinkedInIcon from 'public/icons/social/linkedin.svg';
import TwitterIcon from 'public/icons/social/twitter.svg';
import { trackEvent } from 'src/lib/analytics';
import WitNavbar from '../Navbar/Navbar';
import styles from './Footer.module.scss';

export default function WitFooter({ showContactForm, showButton = true }) {
    return (
        <footer className={styles.pageFooter}>
            {showButton && (
                <WitNavbar
                    showContactForm={showContactForm}
                    id="footer-nav"
                    className={styles.footerNav}
                />
            )}

            <div className={styles.footerContent}>
                <p className="ml-3">
                    Copyright &copy; {new Date().getFullYear()} Wit Labs, Inc.
                    All rights reserved.
                </p>

                <Nav className={styles.socialLinks}>
                    <Nav.Item>
                        <Nav.Link
                            href="https://www.linkedin.com/company/wit-sports/"
                            target="_blank"
                            onClick={() => trackEvent('linkedin_clicked')}
                        >
                            <LinkedInIcon />
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                        <Nav.Link
                            href="https://twitter.com/witcontests/"
                            target="_blank"
                            onClick={() => trackEvent('twitter_clicked')}
                        >
                            <TwitterIcon />
                        </Nav.Link>
                    </Nav.Item>

                    {/* Remove until our social presence is real */}
                    {/* <Nav.Item>
                        <Nav.Link
                            href="https://www.facebook.com/witcontests/"
                            target="_blank"
                            onClick={() => trackEvent('facebook_clicked')}
                        >
                            <FacebookIcon />
                        </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                        <Nav.Link
                            href="https://www.instagram.com/witcontests/"
                            target="_blank"
                            onClick={() => trackEvent('instagram_clicked')}
                        >
                            <InstagramIcon />
                        </Nav.Link>
                    </Nav.Item> */}
                </Nav>
            </div>
        </footer>
    );
}
