import clsx from 'clsx';
import { Button, Navbar, Nav } from 'react-bootstrap';

import styles from 'src/components/Navbar/Navbar.module.scss';
import Logo from 'public/assets/logo.svg';
import { trackEvent } from 'src/lib/analytics';

export default function WitNavbar({
    showContactForm,
    htmlId = 'top-nav',
    className = '',
    links = [],
}) {
    return (
        <div className={clsx(styles.navWrapper, className)}>
            <Navbar expand="lg">
                <div className={styles.navLogoWrapper}>
                    <Navbar.Brand href="/">
                        <Logo />
                    </Navbar.Brand>
                </div>

                <button
                    type="button"
                    className={clsx('navbar-toggler', styles.navbarToggle)}
                    onClick={showContactForm}
                    aria-controls={htmlId}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <Navbar.Collapse id={htmlId} className={styles.navBar}>
                    <div className={styles.navBarLinksContainer}>
                        {links.map((link) => (
                            <Nav.Link
                                key={`link-${link.text}`}
                                href={link.url}
                                className={styles.button}
                                onClick={() => {
                                    trackEvent('nav_blog_clicked');
                                }}
                            >
                                {link.text}
                            </Nav.Link>
                        ))}
                        <Nav.Link
                            href="#"
                            className={[styles.button, styles.contactButton]}
                            onClick={() => {
                                showContactForm();
                                trackEvent('nav_contact_clicked');
                            }}
                        >
                            Contact
                        </Nav.Link>
                    </div>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
