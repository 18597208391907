import Image from 'next/image';

/**
 * Ensures the given source URI does not start with a forward slash. This is
 * necessary for private image loading, where the URI does not have a domain.
 *
 * @param {?string} src
 * @returns A URI without leading forward slash.
 */
const normalizeSrc = (src) => {
    return src?.startsWith('/') ? src.slice(1) : src;
};

/**
 * Exposes the URL builder for Cloudflare Image Resizing. This can be used to
 * optimize images where the "next/image" component cannot be used.
 *
 * @param {Object} options
 * @param {string} options.src The URL of the image to load.
 * @param {number} options.width The width of the resized image. The height is
 * calculated automatically with respect to its aspect ratio.
 * @param {number} options.quality Optional, specifies a quality level (0-100)
 * for the resized image. If not specified, Cloudflare defaults to 80. The
 * quality level is not equivalent to any particular image encoder's quality.
 * @returns {string} A URL for a Cloudflare-optimized variant of the original
 * image.
 */
export const cloudflareLoader = ({ src, width, quality }) => {
    if (!src) {
        return '';
    }

    const params = [`width=${width}`];
    if (quality) {
        params.push(`quality=${quality}`);
    }
    const paramsString = params.join(',');
    return `${CONFIG.cfImages.workerUrl}/${paramsString}/${normalizeSrc(src)}`;
};

/**
 * Extends the next/image optimization component to support loading images
 * via Cloudflare Image Resizing.
 *
 * Only remotely-hosted images (where "src" is a URL string) can be loaded
 * by Cloudflare.
 *
 * Local images (where "src" is a Webpack asset object) will be loaded by
 * Next.js and optimized in the build process.
 *
 * The parameters are the same as "next/image", see its documentation for usage.
 *
 * @param {Object} options
 * @param {string|Object} options.src
 * @see https://nextjs.org/docs/api-reference/next/image
 */
export default function CloudflareImage({ src, ...props }) {
    // Try to gracefully handle missing src
    // <Image> will throw if src is missing
    if (!src) {
        return <img src="" className={props.className} />;
    }

    // Only use the Cloudflare images when "src" is a URL string
    if (typeof src === 'string') {
        return <Image loader={cloudflareLoader} src={src} {...props} />;
    }

    // When "src" is not a string, the default Next.js loader will be used
    return <Image src={src} {...props} />;
}
